import React from "react";
import Card from "./Card";

const Cards = ({ posts, columns }) => {
  return (
    <div
      className={["module__posts-cards cards", `columns-${columns}`].join(" ")}
    >
      {posts.map((post, index) => {
        return <Card key={index} post={{ ...post.post }} />;
      })}
    </div>
  );
};

export default Cards;
