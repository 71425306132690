import React from "react";
import Cards from "../components/Cards";
import Carousel from "../components/Carousel";

const Posts = ({ presentation, posts, columns, cssClass }) => {
  return (
    <div
      className={
        cssClass
          ? ["module module__posts", cssClass].join(" ")
          : "module module__posts"
      }
    >
      <div className="module__box">
        {presentation === "cards" ? (
          <Cards posts={posts} columns={columns} />
        ) : (
          <Carousel
            posts={posts}
            options={{
              type: "carousel",
              startAt: 0,
              perView: columns,
              gap: 0,
              breakpoints: {
                1152: {
                  perView: 2,
                },
                864: {
                  perView: 1,
                },
              },
            }}
          />
        )}
      </div>
    </div>
  );
};

export default Posts;
