import React, { useState, useEffect } from "react";
import Card from "./Card";

import Glide from "@glidejs/glide";

import "../../node_modules/@glidejs/glide/dist/css/glide.core.min.css";
import "../../node_modules/@glidejs/glide/dist/css/glide.theme.min.css";

import "./Carousel.css";

const Carousel = ({ element = "glide", options, children, posts, columns }) => {
  const [slider] = useState(new Glide(`.${element}`, options));
  useEffect(() => {
    slider.mount();
    return () => slider.destroy();
  }, [slider]);

  return (
    <div className={"module__posts-carousel"}>
      <div className={element}>
        <div className="glide__arrows" data-glide-el="controls">
          <button
            className="glide__arrow glide__arrow--left"
            data-glide-dir="<"
          >
            <span className="sr-only">Previous Slide</span>
            <svg
              stroke="currentColor"
              fill="currentColor"
              strokeWidth="0"
              viewBox="0 0 24 24"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
            </svg>
          </button>
          <button
            className="glide__arrow glide__arrow--right"
            data-glide-dir=">"
          >
            <span className="sr-only">Next Slide</span>
            <svg
              stroke="currentColor"
              fill="currentColor"
              strokeWidth="0"
              viewBox="0 0 24 24"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
            </svg>
          </button>
        </div>
        <div className="glide__track" data-glide-el="track">
          <ul className="glide__slides">
            {posts.map((post, index) => {
              return (
                <li className="glide__slide" key={index}>
                  <Card post={{ ...post.post }} />
                </li>
              );
            })}
          </ul>
        </div>
        <div className="glide__bullets" data-glide-el="controls[nav]">
          {posts.map((post, index) => {
            return (
              <button
                key={index}
                className="glide__bullet"
                data-glide-dir={`=${index}`}
              >
                <span className="sr-only">Go To Slide {index}</span>
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Carousel;
